import React, { useState } from "react";
import "./login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate,useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import FooterEbill from "./Ebill/FooterEbill";
import HeaderEbill from "./Ebill/HeaderEbill";
import { useTranslation } from "react-i18next";

function Login() {
    const { t } = useTranslation("registration");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectPath = queryParams.get("redirect") || "/dashboard"; // Default to dashboard if not specified
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [credentialsError, setCredentialsError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const CAPTCHA_SITE_KEY=process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "password" || name === "email") {
      setCredentialsError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setCaptchaError(t("login.p2"));
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (response.ok) {
        localStorage.setItem("userName", result.user.name);
        Cookies.set('token', result.token, { expires: 1, path: '/',secure:true});        
        navigate(redirectPath); // Redirect to the specified path after login
      } else {
        setCredentialsError(result.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    }

  return (
    <div className="main-container">
    <HeaderEbill/>
      <main>
        <div className="form-container">
          <div id="container1">
            <h1 className="text-center mb-3">{t("login.text")}</h1>
            <form className="form" id="loginForm" onSubmit={handleSubmit}>
              <div>
                <div className="email">
                  <label htmlFor="email">{t("login.email")}</label>
                  <div className="input-container">
                    <FontAwesomeIcon icon={faEnvelope} className="icon1" />
                    <input
                      type="email"
                      name="email"
                      placeholder={t("login.p3")}
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="password">{t("login.password")}</label>
                  <div className="input-container">
                    <FontAwesomeIcon icon={faLock} className="icon1" />
                    <input
                      type="password"
                      placeholder={t("login.p4")}
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <span style={{ color: "red", fontWeight: 200 }}>
                    {credentialsError}
                  </span>
                </div>
                <div className="forgot">
                  <Link to="/forgot">{t("login.forgot")}</Link>
                </div>
              </div>
              {/* reCAPTCHA Component */}
              
              <ReCAPTCHA
                sitekey={CAPTCHA_SITE_KEY}
                onChange={setCaptchaValue}
                style={{marginBottom:"8px",width:"100%",display:"flex",justifyContent:"center"}}
              />
              <span id="captcha-message" style={{ color: "red",display:"flex",justifyContent:"center"}}>
                {captchaError}
              </span>
               
              <div className="container" style={{marginTop:"15px"}}>
                <button type="submit" className="submit-btn btn">{t("login.text")}
                </button>
              </div>
              <div className="account">
                <p>
                {t("login.p1")}{" "}
                  <Link to="/register">{t("login.register")}</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </main>
    <FooterEbill/>
    </div>
  );
}

export default Login;