import React, { useState, useRef } from "react";
import "./register.css";
import logo from "../assets/images/logo2.png";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import FooterEbill from "./Ebill/FooterEbill";
import ScrollToTop from "./Ebill/ScrollTop";
import { useTranslation } from "react-i18next";
import HeaderEbill from "./Ebill/HeaderEbill";

function Register() {
   const { t } = useTranslation("registration");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const emailInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const navigate = useNavigate();
  const [passwordValidations, setPasswordValidations] = useState({
    lengthValid: false,
    uppercaseValid: false,
    lowercaseValid: false,
    numberValid: false,
    specialCharValid: false,
  });
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passCondition, setPassCondition] = useState("");
  const [ConfirmPass, setConfirmPass] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [dbotp, setdbOtp] = useState(null);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [Otp, setOtp] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState("");
  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phone: "+" + value,
    });
    setPhoneError("");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "password") {
      validatePassword(value);
      setPassCondition("");
    }
    if (name === "email") {
      setEmailError("");
    }
    if (name === "phone") {
      setPhoneError(" ");
    }
    if (name === "confirmPassword") {
      setConfirmPass("");
    }
  };
  const validatePassword = (password) => {
    const lengthValid = password.length >= 8;
    const uppercaseValid = /[A-Z]/.test(password);
    const lowercaseValid = /[a-z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    setPasswordValidations({
      lengthValid,
      uppercaseValid,
      lowercaseValid,
      numberValid,
      specialCharValid,
    });
  };
  //verify emial
  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/verify-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email }),
      });
      if (response.ok) {
        const data = await response.json();
        setdbOtp(data.otp);
        alert(data.message);
        setIsOtpSent(true);
      } else {
        alert("Failed to send OTP. Please try again.");
        return;
      }
    } catch (err) {
      console.error("Error:", err.message);
      alert("An unexpected error occurred.");
    }
  };

  //verify otp
  const verifyOtp = (e) => {
    if (Otp == dbotp) {
      setIsOtpVerified(true);
    } else {
      alert(t("register.otp_error"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.phone || formData.phone.length < 5) {
      phoneInputRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setPhoneError(t("register.phone_error_scroll"));
      return;
    }
    if (!Object.values(passwordValidations).every(Boolean)) {
      setPassCondition(t("register.password_conditions"));
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setConfirmPass("Password is not matching!");
      return;
    }
    if (!isOtpVerified) {
      setIsEmailVerified(t("register.email_verify_first"));
      emailInputRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    const { confirmPassword, ...dataToSubmit } = formData;
    try {
      const response = await fetch(`${BASE_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSubmit),
      });
      if (!response.ok) {
        const errorMessage = await response.text();
        const result = JSON.parse(errorMessage);
        if (result.error.includes("Email")) {
          setEmailError(result.error);
          emailInputRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          return;
        }
        if (result.error.includes("Phone number")) {
          setPhoneError(result.error);
          phoneInputRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          return;
        }
        alert(result.error);
        return;
      }
      alert(t("register.form_success"));
      navigate("/login");
    } catch (error) {
      console.error("Error:", error.message);
      alert("An unexpected error occurred.");
    }
  };

  return (
    <div>
       <HeaderEbill/>
      <div className="main-container-reg">
        <ScrollToTop />
        <main>
          <div className="form-container register">
            <div id="container">
              <h1>{t("register.register_title")}</h1>
              <form
                className="form"
                id="registrationForm"
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="data">
                    <label htmlFor="name">{t("register.full_name")}</label>
                    <input
                      type="text"
                      placeholder={t("register.full_name_placeholder")}
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="data">
                    <label htmlFor="phone">{t("register.phone")}</label>
                    <div ref={phoneInputRef}>
                    <PhoneInput
                      country={"sa"}
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      enableSearch={true}
                      inputStyle={{
                        width: "100%",
                        height: " 6vh",
                        borderRadius: "5px",
                        border: "2px solid #ddd",
                        fontSize: "14px",
                      }}
                      countryCodeEditable={false}
                      containerClass="phone-input" 
                    />
                    </div>
                    <span id="phoneError" className="error-message">
                      {phoneError}
                    </span>
                  </div>
                  <div className="data">
                    <label htmlFor="email">{t("register.email")}</label>
                    <input
                      type="email"
                      placeholder={t("register.email_placeholder")}
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      ref={emailInputRef}
                      required
                    />
                    <span id="emailError" className="error-message">
                      {emailError}
                    </span>
                  </div>
                  {isOtpSent && !isOtpVerified ? (
                    <div>
                      <label htmlFor="otp">{t("register.otp_label")}</label>
                      <input
                        type="text"
                        placeholder={t("register.otp_placeholder")}
                        name="otp"
                        id="otp"
                        value={Otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      />
                      <button
                        type="button"
                        className="otp-btn"
                        onClick={verifyOtp}
                      >
                        {t("register.verify_otp")}
                      </button>
                    </div>
                  ) : isOtpVerified ? (
                    <span>{t("register.email_verified")}</span>
                  ) : (
                    <div>
                      <p
                        id="emailError"
                        className="error-message"
                        style={{ fontWeight: 200 }}
                      >
                        {isEmailVerified}
                      </p>
                      <button className="otp-btn" onClick={handleVerify}>
                      {t("register.verify_email")}
                      </button>
                    </div>
                  )}
                  <div className="data">
                    <label htmlFor="password">{t("register.password")}</label>
                    <input
                      type="password"
                      placeholder={t("register.password_placeholder")}
                      name="password"
                      id="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                    <div id="passwordRequirements">
                      <p>{t("register.password_requirements")}</p>
                      <ul>
                        <li
                          style={{
                            color: passwordValidations.lengthValid
                              ? "green"
                              : "red",
                          }}
                        >
                          {t("register.password_length")}
                        </li>
                        <li
                          style={{
                            color: passwordValidations.uppercaseValid
                              ? "green"
                              : "red",
                          }}
                        >
                          {t("register.password_uppercase")}
                        </li>
                        <li
                          style={{
                            color: passwordValidations.lowercaseValid
                              ? "green"
                              : "red",
                          }}
                        >
                         {t("register.password_lowercase")}
                        </li>
                        <li
                          style={{
                            color: passwordValidations.numberValid
                              ? "green"
                              : "red",
                          }}
                        >
                          {t("register.password_number")}
                        </li>
                        <li
                          style={{
                            color: passwordValidations.specialCharValid
                              ? "green"
                              : "red",
                          }}
                        >
                         {t("register.password_special")}
                        </li>
                      </ul>
                    </div>
                    <span id="pass-condition" style={{ color: "red" }}>
                      {passCondition}
                    </span>
                  </div>
                  <div className="data confirm-pass">
                    <label htmlFor="confirm-pass">{t("register.confirm_password")}</label>
                    <input
                      type="password"
                      placeholder={t("register.confirm_password_placeholder")}
                      name="confirmPassword"
                      id="confirm-pass"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      required
                    />
                    <span id="message" style={{ color: "red" }}>
                      {ConfirmPass}
                    </span>
                  </div>
                  <div className="terms-conditions" >
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="checkbox"
                      required
                    />
                   {t("register.terms_conditions")}
                    <a href="/term-to-use" target="_blank"  style={{color:"#0f4b60",padding:"0px"}}> {t("register.terms_link")}</a>
                  </div>
                </div>
                <div className="container">
                  <button
                    type="submit"
                    className="submit-btn btn-primary  col-sm-6 mt-6"
                  >
                     {t("register.submit_button")}
                  </button>
                </div>
              </form>
              <div className="account">
                <p>
                {t("register.already_have_account")} <Link to="/login">  {t("register.login")} </Link>
                </p>
              </div>
            </div>
          </div>
        </main>
        <FooterEbill />
      </div>
    </div>
  );
}

export default Register;
