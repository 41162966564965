import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";  // i18next hook
import HeaderEbill from './HeaderEbill';
import FooterEbill from './FooterEbill';
import './Demo.css';
import { motion } from "framer-motion";
import { FaExternalLinkAlt } from "react-icons/fa";

function Demo() {
  const { t, i18n } = useTranslation();  // Add translation hook
  const [demoData, setDemoData] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchDemoData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/demo`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setDemoData(data.result);
      } catch (err) {
        console.error('Error fetching demo data:', err);
      }
    };

    fetchDemoData();
  }, []);

  return (
    <>
      <div className="demo-container-div">
        <HeaderEbill />
        <h1 style={{ marginTop: "20px", color: "#0f4b60" }}>{t('Demo')}</h1>
        <div className="lines" style={{ margin: "0 auto 10px auto" }}></div>
        <div className='inside-demo-container'>
          <motion.div
            className="demo-content"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <div style={{ border: "1px solid #ddd", padding: "10px", borderRadius: "10px" }}>
              <h3>{t('Credentials:')}</h3>
              <div className="form-group test">
                <label>{t('URL')}:</label>
                <p style={{ display: "flex" }}>
                  <a
                    href={`${demoData.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0f4b60" }}
                  >
                    {demoData.url}
                    <FaExternalLinkAlt style={{ marginLeft: "10px" }} />
                  </a>
                </p>
              </div>

              <div className="form-group test">
                <label>{t('ID')}:</label>
                <p>{demoData.id}</p>
              </div>

              <div className="form-group test">
                <label>{t('Password')}:</label>
                <p>{demoData.password}</p>
              </div>
            </div>
          </motion.div>
        </div>
        <FooterEbill />
      </div>
    </>
  );
}

export default Demo;
