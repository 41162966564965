import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";  // i18next hook
import "./Plans.css";
import FooterEbill from "./FooterEbill";
import HeaderEbill from "./HeaderEbill";
import riyalSymbol from "./images/riyal_symbol.svg";

const BASE_URL = process.env.REACT_APP_BASE_URL; 

function Plans() {
  const { t, i18n } = useTranslation();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/plans`);
        if (!response.ok) throw new Error("Failed to fetch packages");
        const data = await response.json();
        setPackages(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <>
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <HeaderEbill />

        <div className="plans-pricing" style={{ flex: "1", paddingBottom: "100px" }}>
          <h2 style={{ color: "#0f4b60", fontWeight: "bold", paddingTop: "20px" }}>
            {t('Our Products')}
          </h2>
          <div className="lines"></div>

          <div className="product-boxes">
            {packages.length > 0 ? (
              packages.map((pkg) => (
                <div className="product-boxes-container" key={pkg.id}>
                  <div className="product-box">
                    <h1>{i18n.language === "ar" ? pkg.plan_name_ar : pkg.plan_name}</h1>
                    <div className="inside-product-box">
                      <h3><img src={riyalSymbol} height={"30px"} alt="Riyal" /> {pkg.amount}</h3>
                      <h4>{pkg.duration} {t('Months')}</h4>

                      {/* Render multilingual features */}
                      {pkg.features?.length > 0 ? (
                        pkg.features.map((feature, index) => (
                          <p key={index}>
                            {i18n.language === "ar" ? feature.text_ar : feature.text}
                          </p>
                        ))
                      ) : (
                        <p>{t('No features available')}</p>
                      )}

                      <Link to="/login" state={{ selectedPlan: pkg }}>
                        <button>{t('Buy Now')}</button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>{t('Loading plans...')}</p>
            )}
          </div>
        </div>

        <FooterEbill />
      </div>
    </>
  );
}

export default Plans;
