import React from 'react';
import "./FooterEbill.css";
import "../../i18n";
import { useTranslation } from 'react-i18next';
function FooterEbill() {
    const { t } = useTranslation(); // Import translation hook
  return (
    <div>
      <footer>
    <div className="footer">
      <div className='inside-footer'>
      <div >
        &copy;{" "}
        <a href="/" target="_blank" className="border-bottom">
         {t("footer_ebill")}
        </a>
        {t("all_rights")}{" "}
        <a
          href="https://Solexnet.com"
          target="_blank"
          rel="noopener noreferrer"
          className="border-bottom"
        >
          {t("solexnet")}
        </a>
      </div>
      <div className="footer-menu">
        <a href="/privacy-policy" target="_blank"> {t("footer_privacy")}</a>
        <a href="/term-to-use" target="_blank"> {t("footer_term")}</a>
        <a href="/refund-policy" target="_blank" style={{borderRight:"none"}}> {t("footer_refund")}</a>
      </div>
      </div>
    </div>
  </footer>
      </div>
  )
}

export default FooterEbill
