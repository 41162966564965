import React from "react";
import { motion } from "framer-motion";
import "./ContactUs.css";
import whatsapp from "./images/whatsapp.png";
import website from "./images/website.png";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
import ScrollToTop from "./ScrollTop";
import { MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation("contact");

  return (
    <>
      <HeaderEbill />
      <ScrollToTop />

      <div className="contact-container">
        <h1>{t("title")}</h1>
        <div className="lines"></div>

        {/* Get In Touch Section */}
        <div className="get-in-touch">
          <h2>{t("get_in_touch.heading")}</h2>
          <p>{t("get_in_touch.description")}</p>
        </div>

        {/* Contact Details */}
        <div className="contact-details">
          
          {/* WhatsApp Scanner */}
          <motion.div
            className="whatsappsacaner"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src={whatsapp} alt="QR Scanner" />
          </motion.div>

          {/* Location and Contact Information */}
          <motion.div
            className="texts"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <div style={{ border: "1px solid #ddd", padding: "10px", borderRadius: "10px" }}>
              <h3>{t("locations_heading")}</h3>
              <ul>
                <li>{t("locations.0")}</li>
                <li>{t("locations.1")}</li>
              </ul>

              <p>
                <MdOutlineEmail color="#0f4b60" /> 
                <a href={`mailto:${t("contact_info.email1")}`} className="email-link">
                  {t("contact_info.email1")}
                </a>
              </p>

              <p>
                <FaWhatsapp color="#0f4b60" /> {t("contact_info.phone1")}
              </p>

              <p>
                <BsTelephone color="#0f4b60" /> {t("contact_info.phone2")}
              </p>

              <p>
                <MdOutlineEmail color="#0f4b60" /> 
                <a href={`mailto:${t("contact_info.email2")}`} className="email-link">
                  {t("contact_info.email2")}
                </a>
              </p>

              <p>
                <FaWhatsapp color="#0f4b60" /> {t("contact_info.phone3")}
              </p>
            </div>
          </motion.div>

          {/* Website Scanner */}
          <motion.div
            className="whatsappsacaner"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src={website} alt="QR Scanner" />
          </motion.div>

        </div>
      </div>

      <FooterEbill />
    </>
  );
}

export default ContactUs;
