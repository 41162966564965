import React, { useEffect, useState } from "react";
import "./GuideFaqs.css";
import HeaderEbill from "./HeaderEbill";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import ScrollToTop from "./ScrollTop";
import FooterEbill from "./FooterEbill";
import { useTranslation } from "react-i18next";

function GuideFaqs() {
  const { i18n } = useTranslation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [videos, setVideos] = useState([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Fetch Videos
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/videos`);
        if (!response.ok) {
          throw new Error("Failed to fetch videos");
        }
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };
    fetchVideos();
  }, [BASE_URL]);

  // Fetch FAQs
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/faqs`);
        if (!response.ok) {
          throw new Error("Failed to fetch FAQs");
        }
        const data = await response.json();
        setFaqs(data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };
    fetchFaqs();
  }, [BASE_URL]);

  return (
    <>
      <HeaderEbill />
      <ScrollToTop />
      <div className="guides">
        <h1 style={{ color: "#0f4b60" }}>
          {i18n.language === "ar" ? "الأدلة والأسئلة الشائعة" : "Guides & FAQs"}
        </h1>
        <div className="line"></div>

        {/* YouTube Videos Section */}
        <div className="video-section">
          {videos.length > 0 ? (
            videos.map((video, index) => (
              <div key={index} className="video-item">
                <h4>{i18n.language === "ar" ? video.title_ar : video.title}</h4>
                <p>
                  {i18n.language === "ar"
                    ? video.description_ar
                    : video.description}
                </p>
                <iframe
                  src={video.url}
                  title={i18n.language === "ar" ? video.title_ar : video.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            ))
          ) : (
            <p>
              {i18n.language === "ar"
                ? "لا توجد مقاطع فيديو متاحة"
                : "No videos available"}
            </p>
          )}
        </div>

        {/* FAQ Section */}
        <section>
          <div className="accordion">
            <div className="hede">
              <h1>
                {i18n.language === "ar"
                  ? "الأسئلة الشائعة"
                  : "Frequently Asked Questions"}
              </h1>
            </div>
            <br />
            {faqs.length > 0 ? (
              faqs.map((faq, index) => (
                <div
                  key={index}
                  className={`accordion-item ${
                    activeIndex === index ? "active" : ""
                  }`}
                  style={{ border: "none" }}
                >
                  <h2
                    className="accordion-header"
                    onClick={() => toggleAccordion(index)}
                  >
                    {i18n.language === "ar" ? faq.question_ar : faq.question}
                    <span className="icon">
                      {activeIndex === index ? (
                        <MdExpandLess
                          style={{ fontSize: "30px", color: "#0f4b60" }}
                        />
                      ) : (
                        <MdExpandMore style={{ fontSize: "30px" }} />
                      )}
                    </span>
                  </h2>
                  <div className="accordion-content">
                    <p>{i18n.language === "ar" ? faq.answer_ar : faq.answer}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>
                {i18n.language === "ar"
                  ? "لا توجد أسئلة متاحة"
                  : "No FAQs available"}
              </p>
            )}
          </div>
        </section>
      </div>
      <FooterEbill />
    </>
  );
}

export default GuideFaqs;
