import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EditPlans.css";
import { FaTrashAlt } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditPlans() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const plan_name = localStorage.getItem("plan_name");

  const [amount, setAmount] = useState("");
  const [duration, setDuration] = useState("");
  const [status, setStatus] = useState("active");
  const [features, setFeatures] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [priority, setPriority] = useState(0);

  useEffect(() => {
    fetchPackagedetails();
  }, []);

  const fetchPackagedetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/plans/edit/${plan_name}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const planDetails = result.result;

      setOriginalData(planDetails);
      setAmount(planDetails.amount);
      setDuration(planDetails.duration);
      setStatus(planDetails.status);
      setPriority(planDetails.priority);

      // ✅ Ensure all features have both English and Arabic text fields
      const updatedFeatures = (planDetails.features || []).map((feature) => {
        if (typeof feature === "string") {
          // Handle old format with only English text
          return { text: feature, text_ar: "" };
        }
        // Handle new format with both English and Arabic text
        return {
          text: feature.text || feature,       // Fallback for old format
          text_ar: feature.text_ar || ""       // Arabic text (empty if missing)
        };
      });

      setFeatures(updatedFeatures);
    } catch (err) {
      console.error("Fetch error:", err);
    }
  };

  const handleFeatureChange = (index, key, value) => {
    const updatedFeatures = [...features];
    updatedFeatures[index][key] = value;
    setFeatures(updatedFeatures);
  };

  const handleAddFeature = () => {
    setFeatures([...features, { text: "", text_ar: "" }]);
  };

  const handleDeleteFeature = (index) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this feature?");
    if (isConfirmed) {
      const updatedFeatures = features.filter((_, i) => i !== index);
      setFeatures(updatedFeatures);
    }
  };

  const hasDataChanged = () => {
    return (
      amount !== originalData.amount ||
      duration !== originalData.duration ||
      status !== originalData.status ||
      priority !== originalData.priority ||
      JSON.stringify(features) !== JSON.stringify(originalData.features)
    );
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();

    if (!hasDataChanged()) {
      toast.error("No data has been edited.");
      return;
    }

    const updatedData = {
      amount,
      duration,
      status,
      features,
      priority,
    };

    try {
      const response = await fetch(`${BASE_URL}/api/plans/update/${plan_name}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      toast.success("Plan updated successfully!");
      navigate("/dashboard/categories");
    } catch (err) {
      console.error("Save error:", err);
      toast.error("There was an error saving the changes.");
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/dashboard/categories");
  };

  return (
    <div className="edit-plan-container">
      <h2>Edit Plan: {plan_name}</h2>

      <form className="edit-plan-form" onSubmit={handleSaveChanges}>
        {/* Amount */}
        <div className="form-group">
          <label htmlFor="amount">Amount (SAR):</label>
          <div className="value-field">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>

        {/* Duration */}
        <div className="form-group">
          <label htmlFor="duration">Duration (Months):</label>
          <div className="value-field">
            <input
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </div>
        </div>

        {/* Status */}
        <div className="form-group">
          <label htmlFor="status">Status:</label>
          <div className="value-field">
            <select
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
        </div>

        {/* Priority */}
        <div className="form-group">
          <label htmlFor="priority">Priority:</label>
          <div className="value-field">
            <input
              type="number"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            />
          </div>
        </div>

        {/* Features */}
        <div className="form-group">
          <div style={{ display: "flex", fontSize: "28px" }}>
            <label className="features">Features:</label>
            <label className="add-features" onClick={handleAddFeature}>
              +
            </label>
          </div>
          <div className="features-containers">
            {features.map((feature, index) => (
              <div key={index} className="feature-items">
                <div className="feature-field" style={{ color: "black" }}>
                  <label>{index + 1}</label>

                  {/* English Feature */}
                  <input
                    type="text"
                    placeholder="Feature (English)"
                    value={feature.text}
                    onChange={(e) =>
                      handleFeatureChange(index, "text", e.target.value)
                    }
                  />

                  {/* Arabic Feature */}
                  <input
                    type="text"
                    placeholder="Feature (Arabic)"
                    value={feature.text_ar}
                    onChange={(e) =>
                      handleFeatureChange(index, "text_ar", e.target.value)
                    }
                  />

                  <button
                    type="button"
                    onClick={() => handleDeleteFeature(index)}
                    className="delete-btn dlt"
                  >
                    <FaTrashAlt className="trash-btn" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Buttons */}
        <div className="save-back-btn-class">
          <button className="back-btn" onClick={handleBack}>
            Back
          </button>
          <button type="submit" className="submit-button">
            Save Changes
          </button>
        </div>
      </form>
      <ToastContainer position="bottom-center" />
    </div>
  );
}

export default EditPlans;
