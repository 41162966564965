import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Faqs.css";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

function Faqs() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [faqs, setFaqs] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const [editingFaq, setEditingFaq] = useState(null);
  const [editingVideo, setEditingVideo] = useState(null);

  const [newFaq, setNewFaq] = useState({
    question: "",
    answer: "",
    priority: 1,
  });
  const [newVideo, setNewVideo] = useState({
    title: "",
    title_ar: "",
    url: "",
    category: "",
    description: "",
    description_ar: "",
    priority: 1,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const faqsResponse = await fetch(`${BASE_URL}/api/faqs`);
        const faqsData = await faqsResponse.json();
        setFaqs(faqsData);

        const videosResponse = await fetch(`${BASE_URL}/api/videos`);
        const videosData = await videosResponse.json();
        setVideos(videosData);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [BASE_URL]);

  const handleEditFaq = (faq) => {
    setEditingFaq({ ...faq });
  };

  const handleEditVideo = (video) => {
    setEditingVideo({ ...video });
  };

  const handleSaveFaq = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/faqs/${editingFaq.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editingFaq),
      });

      if (response.ok) {
        setFaqs(
          faqs.map((faq) => (faq.id === editingFaq.id ? editingFaq : faq))
        );
        toast.success("FAQ updated successfully!");
        setEditingFaq(null);
      } else {
        toast.error("Failed to save FAQ.");
      }
    } catch (error) {
      console.error("Error saving FAQ:", error);
      toast.error("Error saving FAQ.");
    }
  };

  const handleSaveVideo = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/videos/${editingVideo.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editingVideo),
        }
      );
      if (response.ok) {
        setVideos(
          videos.map((video) =>
            video.id === editingVideo.id ? editingVideo : video
          )
        );
        toast.success("Video updated successfully!");
        setEditingVideo(null);
      } else {
        toast.error("Failed to save Video.");
      }
    } catch (error) {
      console.error("Error saving Video:", error);
      toast.error("Error saving Video.");
    }
  };

  const handleCancelEditFaq = () => {
    setEditingFaq(null);
  };

  const handleCancelEditVideo = () => {
    setEditingVideo(null);
  };

  const handleAddFaq = async () => {
    if (!newFaq.question.trim() || !newFaq.answer.trim()) {
      toast.warn("Both question and answer are required!");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/faqs`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newFaq),
      });

      if (response.ok) {
        const createdFaq = await response.json();
        setFaqs([...faqs, createdFaq]);
        setNewFaq({
          question: "",
          answer: "",
          question_ar: "",
          answer_ar: "",
          priority: 1,
        });
        toast.success("FAQ added successfully!");
      } else {
        toast.error("Failed to add FAQ.");
      }
    } catch (error) {
      console.error("Error adding FAQ:", error);
      toast.error("Error adding FAQ.");
    }
  };

  const handleAddVideo = async () => {
    if (
      !newVideo.url.trim() ||
      !newVideo.title.trim() ||
      !newVideo.category.trim()
    ) {
      toast.warn("Title, URL, and category are required!");
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/api/videos`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newVideo),
      });
      if (response.ok) {
        const createdVideo = await response.json();
        setVideos([...videos, createdVideo]);
        setNewVideo({
          title: "",
          url: "",
          category: "",
          description: "",
          priority: 1,
        });
        toast.success("Video added successfully!");
      } else {
        toast.error("Failed to add Video.");
      }
    } catch (error) {
      console.error("Error adding Video:", error);
      toast.error("Error adding Video.");
    }
  };
  const handleDeleteFaq = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this faq?"
    );
    if (!isConfirmed) {
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/api/faqs/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setFaqs(faqs.filter((faq) => faq.id !== id));
        toast.success("FAQ deleted successfully!");
      } else {
        toast.error("Failed to delete FAQ.");
      }
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      toast.error("Error deleting FAQ.");
    }
  };

  const handleDeleteVideo = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this video?"
    );
    if (!isConfirmed) {
      return;
    }
    try {
      const response = await fetch(`${BASE_URL}/api/videos/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setVideos(videos.filter((video) => video.id !== id));
        toast.success("Video deleted successfully!");
      } else {
        toast.error("Failed to delete Video.");
      }
    } catch (error) {
      console.error("Error deleting Video:", error);
      toast.error("Error deleting Video.");
    }
  };

  return (
    <div className="faqs-container">
      <div className="inside-faqs-container">
        <h2 style={{ textAlign: "center", fontWeight: "bold" }}>FAQs</h2>
        {loading ? (
          <p>Loading FAQs...</p>
        ) : (
          <>
            <div className="faq-list">
              {faqs.map((faq) => (
                <div key={faq.id} className="faq-item">
                  {editingFaq?.id === faq.id ? (
                    <>
                      {/* English Question */}
                      <label>Question (EN):</label>
                      <input
                        type="text"
                        value={editingFaq.question}
                        onChange={(e) =>
                          setEditingFaq({
                            ...editingFaq,
                            question: e.target.value,
                          })
                        }
                      />

                      {/* Arabic Question */}
                      <label>Question (AR):</label>
                      <input
                        type="text"
                        value={editingFaq.question_ar}
                        onChange={(e) =>
                          setEditingFaq({
                            ...editingFaq,
                            question_ar: e.target.value,
                          })
                        }
                        dir="rtl" // Arabic text right-to-left
                      />

                      {/* English Answer */}
                      <label>Answer (EN):</label>
                      <textarea
                        value={editingFaq.answer}
                        onChange={(e) =>
                          setEditingFaq({
                            ...editingFaq,
                            answer: e.target.value,
                          })
                        }
                      />

                      {/* Arabic Answer */}
                      <label>Answer (AR):</label>
                      <textarea
                        value={editingFaq.answer_ar}
                        onChange={(e) =>
                          setEditingFaq({
                            ...editingFaq,
                            answer_ar: e.target.value,
                          })
                        }
                        dir="rtl"
                      />

                      <label>Priority:</label>
                      <input
                        type="number"
                        value={editingFaq.priority}
                        onChange={(e) =>
                          setEditingFaq({
                            ...editingFaq,
                            priority: parseInt(e.target.value, 10),
                          })
                        }
                      />

                      <button
                        onClick={handleSaveFaq}
                        className="save-btn"
                        style={{ color: "green", border: "2px solid green" }}
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancelEditFaq}
                        className="cancel-btn"
                        style={{ color: "red", border: "2px solid red" }}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      {/* Display English Question and Answer */}
                      <p>
                        <strong>Question (EN):</strong> {faq.question}
                      </p>
                      <p>
                        <strong>Answer (EN):</strong> {faq.answer}
                      </p>

                      {/* Display Arabic Question and Answer */}
                      <p dir="rtl">
                        <strong>السؤال (AR):</strong> {faq.question_ar}
                      </p>
                      <p dir="rtl">
                        <strong>الإجابة (AR):</strong> {faq.answer_ar}
                      </p>

                      <p>
                        <strong>Priority:</strong> {faq.priority}
                      </p>

                      <button
                        onClick={() => handleEditFaq(faq)}
                        className="faq-edit-button"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteFaq(faq.id)}
                        className="faq-delete-button"
                      >
                        <FaTrashAlt />
                      </button>
                    </>
                  )}
                </div>
              ))}
            </div>

            <div className="add-faq">
              <h3>Add New FAQ</h3>

              {/* English Fields */}
              <input
                type="text"
                placeholder="Enter Question in English"
                value={newFaq.question}
                onChange={(e) =>
                  setNewFaq({ ...newFaq, question: e.target.value })
                }
                className="faq-input"
              />
              <textarea
                placeholder="Enter Answer in English"
                value={newFaq.answer}
                onChange={(e) =>
                  setNewFaq({ ...newFaq, answer: e.target.value })
                }
                className="faq-input"
              />

              {/* Arabic Fields */}
              <input
                type="text"
                placeholder="Enter Question in Arabic"
                value={newFaq.question_ar || ""}
                onChange={(e) =>
                  setNewFaq({ ...newFaq, question_ar: e.target.value })
                }
                className="faq-input"
                dir="rtl"
              />
              <textarea
                placeholder="Enter Answer in Arabic"
                value={newFaq.answer_ar || ""}
                onChange={(e) =>
                  setNewFaq({ ...newFaq, answer_ar: e.target.value })
                }
                className="faq-input"
                dir="rtl"
              />

              <label>Priority:</label>
              <input
                placeholder="Enter Priority"
                type="number"
                value={newFaq.priority}
                onChange={(e) =>
                  setNewFaq({
                    ...newFaq,
                    priority: parseInt(e.target.value, 10),
                  })
                }
              />
              <button onClick={handleAddFaq} className="faq-add-btn">
                Add FAQ
              </button>
            </div>
          </>
        )}

        <h2
          style={{ marginTop: "50px", textAlign: "center", fontWeight: "bold" }}
        >
          Videos
        </h2>
        {loading ? (
          <p>Loading Videos...</p>
        ) : (
          <>
            <div className="video-list">
              {videos.map((video) => (
                <div key={video.id} className="video-item">
                  {editingVideo?.id === video.id ? (
                    <>
                      {/* Edit Fields */}
                      <input
                        type="text"
                        value={editingVideo.title}
                        onChange={(e) =>
                          setEditingVideo({
                            ...editingVideo,
                            title: e.target.value,
                          })
                        }
                        placeholder="Title (EN)"
                      />
                      <input
                        type="text"
                        dir="rtl"
                        value={editingVideo.title_ar}
                        onChange={(e) =>
                          setEditingVideo({
                            ...editingVideo,
                            title_ar: e.target.value,
                          })
                        }
                        placeholder="العنوان (AR)"
                      />
                      <input
                        type="text"
                        value={editingVideo.url}
                        onChange={(e) =>
                          setEditingVideo({
                            ...editingVideo,
                            url: e.target.value,
                          })
                        }
                        placeholder="Video URL"
                      />
                      <input
                        type="text"
                        value={editingVideo.category}
                        onChange={(e) =>
                          setEditingVideo({
                            ...editingVideo,
                            category: e.target.value,
                          })
                        }
                        placeholder="Category"
                      />
                      <textarea
                        value={editingVideo.description}
                        onChange={(e) =>
                          setEditingVideo({
                            ...editingVideo,
                            description: e.target.value,
                          })
                        }
                        placeholder="Description (EN)"
                      />
                      <textarea
                        value={editingVideo.description_ar}
                        onChange={(e) =>
                          setEditingVideo({
                            ...editingVideo,
                            description_ar: e.target.value,
                          })
                        }
                        dir="rtl"
                        placeholder="الوصف (AR)"
                      />
                      <label>Priority:</label>
                      <input
                        type="number"
                        value={editingVideo.priority}
                        onChange={(e) =>
                          setEditingVideo({
                            ...editingVideo,
                            priority: parseInt(e.target.value, 10),
                          })
                        }
                      />
                      <button
                        onClick={handleSaveVideo}
                        className="save-btn"
                        style={{ color: "green", border: "2px solid green" }}
                      >
                        Save
                      </button>
                      <button
                        onClick={handleCancelEditVideo}
                        className="cancel-btn"
                        style={{ color: "red", border: "2px solid red" }}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      {/* Display Fields */}
                      <p >
                        <strong>Title (EN):</strong> {video.title}
                      </p>
                      <p dir="rtl">
                        <strong >العنوان (AR):</strong> {video.title_ar}
                      </p>
                      <p>
                        <strong>URL:</strong> {video.url}
                      </p>
                      <p>
                        <strong>Category:</strong> {video.category}
                      </p>
                      <p>
                        <strong>Description (EN):</strong> {video.description}
                      </p>
                      <p dir="rtl">
                        <strong>الوصف (AR):</strong> {video.description_ar}
                      </p>
                      <p>
                        <strong>Priority:</strong> {video.priority}
                      </p>
                      <button
                        onClick={() => handleEditVideo(video)}
                        className="faq-edit-button"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteVideo(video.id)}
                        className="faq-delete-button"
                      >
                        <FaTrashAlt />
                      </button>
                    </>
                  )}
                </div>
              ))}
            </div>

            <div className="add-video">
              <h3>Add New Video</h3>
              <input
                type="text"
                placeholder="Enter Title (English)"
                value={newVideo.title}
                onChange={(e) =>
                  setNewVideo({ ...newVideo, title: e.target.value })
                }
                className="video-input"
              />
              <input
                type="text"
                placeholder="أدخل العنوان (بالعربية)"
                value={newVideo.title_ar}
                onChange={(e) =>
                  setNewVideo({ ...newVideo, title_ar: e.target.value })
                }
                dir="rtl"
                className="video-input"
              />
              <input
                type="text"
                placeholder="Enter URL"
                value={newVideo.url}
                onChange={(e) =>
                  setNewVideo({ ...newVideo, url: e.target.value })
                }
                className="video-input"
              />
              <input
                type="text"
                placeholder="Enter Category"
                value={newVideo.category}
                onChange={(e) =>
                  setNewVideo({ ...newVideo, category: e.target.value })
                }
                className="video-input"
              />
              <textarea
                placeholder="Enter Description (English)"
                value={newVideo.description}
                onChange={(e) =>
                  setNewVideo({ ...newVideo, description: e.target.value })
                }
                className="video-input"
              />
              <textarea
                placeholder="أدخل الوصف (بالعربية)"
                value={newVideo.description_ar}
                onChange={(e) =>
                  setNewVideo({ ...newVideo, description_ar: e.target.value })
                }
                dir="rtl"
                className="video-input"
              />

              <button
                onClick={handleAddVideo}
                className="faq-add-btn"
                style={{ border: "2px solid #082f3e", color: "#082f3e" }}
              >
                Add Video
              </button>
            </div>
          </>
        )}

        <ToastContainer position="top-center" />
      </div>
    </div>
  );
}

export default Faqs;
