import { useTranslation } from "react-i18next";
import { useState } from "react";
import "./i18n";

const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const toggleLanguage = () => {
    const newLang = lang === "ar" ? "en" : "ar";
    i18n.changeLanguage(newLang).then(() => {
      document.documentElement.lang = newLang;
      document.documentElement.dir = newLang === "ar" ? "rtl" : "ltr";
      setLang(newLang); // Update state to force re-render
    });
  };

  return (
    <button className="lang-toggle" onClick={toggleLanguage}>
      {lang === "ar" ? "English" : "العربية"}
    </button>
  );
};

export default LanguageToggle;
