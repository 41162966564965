import React, { useState, useEffect } from "react";
import "./WalletRecharge.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import riyalSymbol from "./images/riyal_symbol.svg";
function WalletRecharge() {
  const token = Cookies.get("token");
  const [bankDetails, setBankDetails] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);
  const [formData, setFormData] = useState({
    senderName: "",
    amount: "",
    receipt: null,
  });
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    if (!token) return;
    const decodedToken = jwtDecode(token);
    setUserEmail(decodedToken.email);
  }, [token]);

  useEffect(() => {
    if (!userEmail) return;
    fetchWallet();
  }, [userEmail]);

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/bank-details`);
        if (!response.ok) throw new Error("Failed to fetch bank details");
        const data = await response.json();
        setBankDetails(data);
      } catch (error) {
        console.error("Error fetching bank details:", error);
      }
    };

    fetchBankDetails();
  }, []);
  const fetchWallet = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get/wallet/${userEmail}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (response.ok) {
        setWalletBalance(result.wallet);
      } else {
        alert(result.error);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value, // Handle file inputs separately
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { senderName, amount, receipt } = formData;

    if (!senderName || !amount || !receipt) {
      alert("All fields are required.");
      return;
    }

    const formDataObj = new FormData();
    formDataObj.append("senderName", senderName);
    formDataObj.append("amount", amount);
    formDataObj.append("receipt", receipt);

    try {
      console.log(formDataObj);
      const response = await fetch(
        `${BASE_URL}/api/wallet-recharge/${userEmail}`,
        {
          method: "POST",
          body: formDataObj,
        }
      );

      if (!response.ok) throw new Error("Failed to submit request.");

      alert("Wallet recharge request submitted successfully.");
      navigate(-1); // Redirect to another page if needed
    } catch (error) {
      console.error("Error submitting wallet recharge request:", error);
    }
  };
  return (
    <div className="wallet-recharge-container">
      <div
        style={{
          padding: "10px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <h2 className="wallet-recharge-title">
          Request adding a credit to the wallet
        </h2>
        <p className="wallet-recharge-description">
          You can charge your wallet after requesting a transfer to our below
          bank account.
        </p>

        {bankDetails ? (
          <div className="bank-details">
          <h3 className="bank-details-title">Bank Account Details</h3>
          <div className="bank-details-card">
            <p className="bank-detail">
              <span className="detail-label">Account Name:</span>
              <span className="detail-value">{bankDetails.account_name}</span>
            </p>
            <p className="bank-detail">
              <span className="detail-label">Bank Name:</span>
              <span className="detail-value">{bankDetails.bank_name}</span>
            </p>
            <p className="bank-detail">
              <span className="detail-label">Account Number:</span>
              <span className="detail-value">{bankDetails.account_number}</span>
            </p>
            <p className="bank-detail">
              <span className="detail-label">IBAN:</span>
              <span className="detail-value">{bankDetails.iban}</span>
            </p>
          </div>
        </div>
        
        ) : (
          <p>Loading bank details...</p>
        )}

        <h3 className="form-title">
          Application form for adding balance after transfer
        </h3>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="senderName">Sender name</label>
            <input
              type="text"
              id="senderName"
              name="senderName"
              value={formData.senderName}
              onChange={handleChange}
              placeholder="Enter sender name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Enter amount (SAR)"
            />
          </div>

          <div className="wallet-balance">
            <div className="wallet-balance-container">
              <p className="wallet-balance-text">
                Balance in Wallet:
                <strong className="wallet-balance-amount">
                 <img src={riyalSymbol} height={"27px"}></img> {walletBalance} 
                </strong>
              </p>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="receipt">Transfer receipt</label>
            <input
              type="file"
              id="receipt"
              name="receipt"
              onChange={handleChange}
              style={{ color: "black" }}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <button
              type="button"
              className="back-button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button type="submit" className="confirm-button">
              Submit Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default WalletRecharge;
