import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import featuresEN from "./locales/en/features.json"; 
import contactEN from "./locales/en/contact.json";
import registrationEN from "./locales/en/registration.json";
import policyEN from "./locales/en/policies.json";

import translationAR from "./locales/ar/translation.json";
import featuresAR from "./locales/ar/features.json";
import contactAR from "./locales/ar/contact.json";
import registrationAR from "./locales/ar/registration.json";
import policyAR from "./locales/ar/policies.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
        features: featuresEN,
        contact:contactEN,
        registration:registrationEN,
        policy:policyEN
      },
      ar: {
        translation: translationAR,
        features: featuresAR,
        contact: contactAR,
        registration:registrationAR,
        policy:policyAR
      }
    },
    lng: "en", // Default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    debug: true // Debugging enabled for development
  });

export default i18n;

