import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
import ScrollToTop from "./ScrollTop";
import "./Features.css";
// Import images
import invoiceImage from "./images/invoice.png";
import deliverable from "./images/deliverable.png";
import openFolder from "./images/open-folder-with-document.png";
import warehouse from "./images/warehouse.png";
import tag from "./images/tag.png";
import deposit from "./images/deposit.png";
import chat from "./images/chat.png";
import wallet from "./images/wallet.png";
import salary from "./images/salary.png";
import cash from "./images/cash.png";
import profit from "./images/profit.png";
import business from "./images/business.png";
import powerful from "./images/powarful.png";
import customer from "./images/customer-service.png";
import gear from "./images/gear.png";
import log from "./images/log-document.png";
import translation from "./images/translation.png";
import sales from "./images/sales.png";

// Map icon names to actual image imports
const iconMap = {
  invoiceImage,
  deliverable,
  openFolder,
  warehouse,
  tag,
  deposit,
  chat,
  wallet,
  salary,
  cash,
  profit,
  business,
  powerful,
  customer,
  gear,
  log,
  translation,
  sales
};

const Features = () => {
  const { t } = useTranslation("features");

  return (
    <>
      <HeaderEbill />
      <div className="features-container">
        <div className="heading-container">
          <h2>
            <span style={{ color: "#0f4b60", fontWeight: "bold" }}>
              {t("features_heading")}
            </span>
          </h2>
          <div className="lines"></div>
        </div>
        <div className="features-grid">
          {t("features_list", { returnObjects: true }).map((feature, index) => (
            <motion.div
              className="feature-item"
              key={index}
              initial={{ opacity: 0, scale: 0.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2, delay: index * 0.1 }}
            >
              <div className="feature-icon">
                <img src={iconMap[feature.icon]} alt={feature.title} />
              </div>
              <h5 className="feature-title">{feature.title}</h5>
              <p className="feature-description">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
      <FooterEbill />
      <ScrollToTop />
    </>
  );
};

export default Features;
