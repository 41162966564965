import React from "react";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
import { useTranslation } from "react-i18next";
function Termtouse() {
  const {t}=useTranslation('policy');
  return (
    <>
      <HeaderEbill />
      <div style={{ marginBottom: "100px" }}>
        <div className="guides" style={{ paddingBottom: "0px" }}>
          <h1 style={{ color: "#0f4b60" }}>{t('heading2')}</h1>
          <div className="line"></div>
        </div>
        <div className="privacy" style={{ padding: "0px 30px" }}>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
             {t("h1")}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t("p7")}
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h2")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("terms_conditions.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t('h3')}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("account_administration.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
             {t("h4")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("intellectual_property.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h5")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("content_and_rights.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
           {t("h6")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("subscription_and_payment.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t('h7')}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("data_security.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t('h8')}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("maintenance_downtime_data_loss.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
             {t('h9')}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("confidentiality_privacy.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h10")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("disclaimer_warranties.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h11")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("limitation_liability_indemnity.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
             {t('h12')}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("termination.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h13")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("governing_law_and_disputes.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t('h14')}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("miscellaneous.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t('h15')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
             {t("p15")}
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h16")}
            </h2>
            <ol style={{ color: "#0f4b60", fontSize: "18px" }}>
            {t("confidentiality.items", { returnObjects: true }).map(
              (item, index) => (
                <li key={index} style={{ marginBottom: "15px" }}>
                  {item}
                </li>
              )
            )}
            </ol>
          </div>
        </div>
      </div>
      <FooterEbill />
    </>
  );
}

export default Termtouse;
