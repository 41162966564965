import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import './Dash.css'; 
import Header from './Header';
import Sidebar from './Sidebar';
import Home from './Home';
import Users from './Users';
import EditUser from "./EditUser";
import AddUsers from "./AddUsers";
import Subscriptions from "./Subscriptions";
import Details from "./Details";
import AddProducts from "./AddProducts";
import Categories from "./Categories";
import Profile from "./Profile";
import Cookies from 'js-cookie';
import Invoices from "./Invoices";
import EditPlans from "./EditPlans";
import CreatePKg from "./Create_pkg";
import Payments from "./Payments";
import RenewalPayments from "./RenewalPayments";
import Faqs from "./Faqs";
import ImportDomain from "./importDomain";
import Comission from "./Comission";
import WalletRecharge from "./WalletRecharge";
import AccountDetails from "./AccountDetails";
import WalletRechargeRequests from "./WalletRechargeRequests";
import DemoData from "./DemoData";
import RechargeRequest from "./RechargeRequest";
import Coupons from "./Coupons";

function Dash() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const navigate = useNavigate();
  const timeoutRef = useRef(null); 

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  // Logout function to clear the token and navigate to the login page
  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/login");
  };

  // Reset inactivity timer
  const resetInactivityTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      handleLogout();
    }, 30 * 60 * 1000); // 30 minutes
  };

  // Attach event listeners for user activity
  useEffect(() => {
    const handleActivity = () => resetInactivityTimer();
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("click", handleActivity);

    resetInactivityTimer(); 

    return () => {
      // Cleanup event listeners and timeout on component unmount
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("click", handleActivity);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Check authentication token
  useEffect(() => {
    const checkAuthToken = async () => {
      const authToken = Cookies.get("token");
      if (!authToken) {
        navigate("/login");
      } else {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/verify-token`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`
            }
          });

          if (!response.ok) {
            throw new Error('Token is invalid');
          }
        } catch (error) {
          console.error('Error:', error);
          Cookies.remove("token");
          navigate("/login");
        }
      }
    };
    checkAuthToken();
  }, [navigate]);

  return (
    <div className={`grid-container ${openSidebarToggle ? '' : 'sidebar-closed'}`}>
      <Header OpenSidebar={OpenSidebar} Logout={handleLogout} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <div className="content">
        <Routes>
          <Route index element={<Home />} />
          <Route path="users" element={<Users />} />
          <Route path="edit" element={<EditUser />} />
          <Route path="add-users" element={<AddUsers />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="details" element={<Details />} />
          <Route path="add_products" element={<AddProducts />} />
          <Route path="profile" element={<Profile />} />
          <Route path="categories" element={<Categories />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="plans-edit" element={<EditPlans />} />
          <Route path="create-pkg" element={<CreatePKg />} />
          <Route path="payments" element={<Payments />} />
          <Route path="renewalpayments" element={<RenewalPayments />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="import" element={<ImportDomain />} />
          <Route path="comission" element={<Comission/>}/>
          <Route path="walletrecharge" element={<WalletRecharge/>}/>
          <Route path="accountdetails" element={<AccountDetails/>}/>
          <Route path="wallet-recharge-requests" element={<WalletRechargeRequests />} />
          <Route path="demodata" element={<DemoData/>}/>
          <Route path="recharge-request" element={<RechargeRequest/>}/>
          <Route path="coupons" element={<Coupons/>}/>
        </Routes>
      </div>
    </div>
  );
}

export default Dash;
