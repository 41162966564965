import React from "react";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
import { useTranslation } from "react-i18next";

function Privacypolicy() {
  const { t } = useTranslation('policy');

  return (
    <>
      <HeaderEbill />
      <div style={{ marginBottom: "100px" }}>
        <div className="guides" style={{ paddingBottom: "0px" }}>
          <h1 style={{ color: "#0f4b60" }}>{t('heading')}</h1>
          <div className="line"></div>
        </div>
        <div className="privacy" style={{ padding: "0px 30px" }}>
          
          {/* Introduction */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t1')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('c1')} <a href="/">{t('c11')}</a> {t('c12')}{" "}
              <a href="https://solexnet.com/">{t('c13')}</a>
              {t('c14')}
            </p>
          </div>

          {/* Data Collection */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t2')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('c2')}
            </p>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('c3')}
            </p>
          </div>

          {/* Data Usage */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('usage_purposes.title')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('c4')}
            </p>
            <ul>
              {t("usage_purposes.items", { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          {/* Data Sharing */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t3')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('p1')}
            </p>
            <ul>
              {t("data_sharing.items", { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          {/* International Data Transfers */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t4')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('p2')}
            </p>
          </div>

          {/* Security */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t5')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('p3')}
              <br />
              {t('p13')}
            </p>
          </div>

          {/* Data Retention */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t6')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('p4')}
            </p>
          </div>

          {/* Your Rights */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t7')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('p5')}
            </p>
            <ul>
              {t("data_rights.items", { returnObjects: true }).map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          {/* Contact Us */}
          <div>
            <h2 style={{ color: "black", fontWeight: "bold", fontSize: "28px", margin: "10px 0px" }}>
              {t('t8')}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t('p6')}
            </p>
          </div>
        </div>
      </div>
      <FooterEbill />
    </>
  );
}

export default Privacypolicy;
