import React from "react";
import HeaderEbill from "./HeaderEbill";
import FooterEbill from "./FooterEbill";
import { useTranslation } from "react-i18next";
function Refundpolicy() {
  const {t}=useTranslation('policy');
  return (
    <>
      <HeaderEbill />
      <div style={{ marginBottom: "100px" }}>
        <div className="guides" style={{ paddingBottom: "0px" }}>
          <h1 style={{ color: "#0f4b60" }}>{t('heading3')}</h1>
          <div className="line"></div>
        </div>
        <div className="privacy" style={{ padding: "0px 30px" }}>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h17")}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t("p17")}
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h18")}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
              {t("p18")}
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
             {t("h19")}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
             {t("p19")}
            </p>
          </div>
          <div>
            <h2
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "23px",
                margin: "10px 0px",
              }}
            >
              {t("h20")}
            </h2>
            <p style={{ color: "#0f4b60", fontSize: "18px" }}>
             {t("p20")}
            </p>
          </div>
        </div>
      </div>
      <FooterEbill />
    </>
  );
}

export default Refundpolicy;
