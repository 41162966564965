import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './HeaderEbill.css';
import Logo from './images/logo2.png';
import "../../i18n";
import LanguageToggle from "../../LanguageToggle";
function HeaderEbill() {
  const { t } = useTranslation(); // Import translation hook
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isActive = (path) => location.pathname === path;



  return (
    <header className={`header-ebill ${scrolled ? 'scrolled' : ''}`}>
      <div className="logo-container">
        <img src={Logo} alt="Logo" />
      </div>
      <div
        className={`hamburger ${menuOpen ? 'open' : ''}`}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nav className={`navbars ${menuOpen ? 'open' : ''}`}>
        <ul className="navbar-list">
          <li className={`navbar-item ${isActive('/') ? 'active' : ''}`}>
            <Link to="/">{t('Home')}</Link>
          </li>
          <li className={`navbar-item ${isActive('/features') ? 'active' : ''}`}>
            <Link to="/features">{t('Features')}</Link>
          </li>
          <li className={`navbar-item ${isActive('/pricing') ? 'active' : ''}`}>
            <Link to="/pricing">{t('Pricing')}</Link>
          </li>
          <li className={`navbar-item ${isActive('/guide-faqs') ? 'active' : ''}`}>
            <Link to="/guide-faqs">{t('Guide')}</Link>
          </li>
          <li className={`navbar-item ${isActive('/demo') ? 'active' : ''}`}>
            <Link to="/demo">{t('Demo')}</Link>
          </li>
          <li className={`navbar-item ${isActive('/contactus') ? 'active' : ''}`}>
            <Link to="/contactus">{t('Contact')}</Link>
          </li>
          <li className={`navbar-item ${isActive('/login') ? 'active' : ''}`}>
            <Link to="/login">{t('Login')}</Link>
          </li>
          <LanguageToggle />
        </ul>
      </nav>
     
    </header>
  );
}

export default HeaderEbill;
