import React, { useState } from "react";
import { useTranslation } from "react-i18next";  // i18next hook
import "./forgot.css";
import logo from "../assets/images/logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import FooterEbill from "./Ebill/FooterEbill";

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();  // Add translation hook
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [otp, setOtp] = useState(null);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpVerified, setOtpVerrified] = useState("");
  const [passCondition, setPassCondition] = useState("");
  const [passwordValidations, setPasswordValidations] = useState({
    lengthValid: false,
    uppercaseValid: false,
    lowercaseValid: false,
    numberValid: false,
    specialCharValid: false,
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const [isconfirmPassAlert, setIsConfirmPassAlert] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "password") {
      validatePassword(value);
      setPassCondition("");
    }
    if (name === "confirmPassword") {
      setIsConfirmPassAlert("");
    }
  };

  // Send OTP to email
  const sendOtp = async (e) => {
    e.preventDefault();
    const email = formData.email;
    try {
      const response = await fetch(`${BASE_URL}/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        const generatedOtp = data.otp;
        setOtp(generatedOtp);
        setIsOtpSent(true);
        setEnteredOtp("");
        alert(data.message);
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert(t("Error sending OTP"));
      console.error("Error:", error.message);
    }
  };

  // Verify the entered OTP
  const verifyOtp = (e) => {
    e.preventDefault();
    if (parseInt(enteredOtp) === otp) {
      alert(t("OTP verified! You can now reset your password."));
      setIsOtpVerified(true);
      setOtpVerrified("");
    } else {
      setOtpVerrified(t("Wrong OTP! Please try again."));
      setEnteredOtp("");
    }
  };

  // Validate password requirements
  const validatePassword = (password) => {
    const lengthValid = password.length >= 8;
    const uppercaseValid = /[A-Z]/.test(password);
    const lowercaseValid = /[a-z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    setPasswordValidations({
      lengthValid,
      uppercaseValid,
      lowercaseValid,
      numberValid,
      specialCharValid,
    });
  };

  // Update password
  const updatePassword = async (e) => {
    e.preventDefault();
    if (!Object.values(passwordValidations).every(Boolean)) {
      setPassCondition(t("Please meet all password requirements!"));
      return;
    }
    if (!isOtpVerified) {
      alert(t("Please verify the OTP first."));
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setIsConfirmPassAlert(t("Passwords do not match!"));
      return;
    }
    const { confirmPassword, ...dataToSubmit } = formData;
    try {
      const response = await fetch(`${BASE_URL}/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSubmit),
      });
      const data = await response.json();
      alert(data.message);
      if (response.ok) {
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error.message);
      alert(t("An unexpected error occurred. Please try again."));
    }
  };

  return (
    <div className="main-container-forgot">
      <header>
        <div className="nav">
          <img
            src={logo}
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </header>
      <main>
        <div className="form-container">
          <div id="container1">
            <h1 className="text-center mb-3">{t("Forgot Password")}</h1>
            {!isOtpSent ? (
              <form onSubmit={sendOtp}>
                <div className="email">
                  <label htmlFor="email">{t("Email")}</label>
                  <div className="input-container">
                    <FontAwesomeIcon icon={faEnvelope} className="icon1" />
                    <input
                      type="email"
                      placeholder={t("Enter your registered email")}
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="container">
                  <button type="submit" className="submit-btn btn-primary">
                    {t("Send OTP")}
                  </button>
                </div>
              </form>
            ) : (
              <div>
                <form onSubmit={verifyOtp}>
                  <div>
                    <label htmlFor="otp">{t("Enter OTP")}</label>
                    <input
                      type="text"
                      id="otp"
                      value={enteredOtp}
                      onChange={(e) => setEnteredOtp(e.target.value)}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="submit-btn btn-primary otp-btn"
                    style={{ marginTop: "10px" }}
                  >
                    {t("Verify OTP")}
                  </button>
                </form>
                {!isOtpVerified ? (
                  <span style={{ color: "red" }}>{otpVerified}</span>
                ) : (
                  <span style={{ color: "green" }}>{otpVerified}</span>
                )}
              </div>
            )}

            {isOtpVerified && (
              <form onSubmit={updatePassword}>
                <label htmlFor="password">{t("New Password")}</label>
                <input
                  type="password"
                  placeholder={t("Enter new password")}
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <div id="passwordRequirements">
                  <p>{t("Password must be:")}</p>
                  <ul>
                    <li style={{ color: passwordValidations.lengthValid ? "green" : "red" }}>
                      {t("At least 8 characters long")}
                    </li>
                    <li style={{ color: passwordValidations.uppercaseValid ? "green" : "red" }}>
                      {t("Contain at least one uppercase letter")}
                    </li>
                    <li style={{ color: passwordValidations.lowercaseValid ? "green" : "red" }}>
                      {t("Contain at least one lowercase letter")}
                    </li>
                    <li style={{ color: passwordValidations.numberValid ? "green" : "red" }}>
                      {t("Contain at least one number")}
                    </li>
                    <li style={{ color: passwordValidations.specialCharValid ? "green" : "red" }}>
                      {t("Contain at least one special character")}
                    </li>
                  </ul>
                </div>
                <label htmlFor="confirm-pass">{t("Confirm Password")}</label>
                <input
                  type="password"
                  placeholder={t("Confirm new password")}
                  name="confirmPassword"
                  id="confirm-pass"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
                <div className="container">
                  <button type="submit" className="submit-btn btn-primary">
                    {t("Update Password")}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </main>
      <FooterEbill />
    </div>
  );
};

export default ForgotPassword;
